
import { Component, Vue, Provide, Emit, Prop, Watch } from 'vue-property-decorator'
import { mobileRegExp } from '@/utils/reg'
import smsMixin from '@/mixins/sms'
import httpHelper from '@/utils/request'
import {
  getYiqianbaoAuthCode,
  getYiqianbaoSource,
  getCCBId,
  setCCBSchoolId,
  setLiangChuAuth,
  getGATId,
  setGATUserId,
  getCCBNJInfo,
  setCCBNJInfo,
  setCCBNJcampusId,
  setSinoepcAuth,
  getSinoepcInfo,
  getXFTData,
  getXFTSign
} from '@/utils/cookies'
import configs from '@/configs'
import GlobalMixin from '@/mixins/global'
import randomString from '@/utils/random'
import { getGoBackUrl } from '@/utils/gobackurl'
import { parseSearch } from '@/utils/env'
const CryptoJS = require('crypto-js')
// console.log(AES)

@Component({
  name: 'VerificationCode',
  components: {
  },
  mixins: [smsMixin, GlobalMixin]
})

export default class VerificationCode extends Vue {
  public name = 'VerificationCode'

  @Prop({
    type: String,
    default: ''
  }) mobile!: string

  @Prop({
    type: String,
    default: ''
  }) times!: string
  @Prop({
    type: Number,
    default: 4
  }) grantType!: number
  @Prop({
    type: Number,
    default: 3
  }) smsType!: number

  @Provide() public code: string = ''
  @Provide() public codeArr: any = []
  @Provide() public errorMsg: string = ''
  @Provide() public lastMobile: string = ''
  @Provide() public loding: boolean = false

  @Emit()
  public async input () {
    await this.login()
  }
  @Emit()
  public async login () {
    this.codeArr = String(this.code).split('') || []
    if (this.codeArr.length === 6) {
      if (this.loding) {
        return false
      }
      this.loding = true
      let type = ''
      const parameter:any = {
        phone: this.mobile,
        smsCode: this.code
      }
      switch (this.grantType) {
        case 3:
          type = 'Password'
          break
        case 4:
          type = 'Auth_PhoneSms'
          break
        case 5:
          type = 'Auth_WeChat'
          try {
            const res = await httpHelper.getAuthLogin(parameter, 'authorize/wechat')
            this.goTo(res, 9, 5)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 5)
            this.errorMsg = err.msg
          }
          break
        case 6:
          type = 'Auth_AliPay'
          try {
            const res = await httpHelper.getAuthLogin(parameter, 'authorize/alipay')
            this.goTo(res, 9, 6)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 6)
            this.errorMsg = err.msg
          }
          break
        case 7:
          type = 'Auth_QQ'
          try {
            const res = await httpHelper.getAuthLogin(parameter, 'authorize/qq')
            this.goTo(res, 9, 7)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 7)
            this.errorMsg = err.msg
          }
          break
        case 8:
          type = 'Auth_Weibo'
          try {
            const res = await httpHelper.getAuthLogin(parameter, 'authorize/weibo')
            this.goTo(res, 9, 8)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 8)
            this.errorMsg = err.msg
          }
          break
        case 9:// 异常IP
          type = 'CityChange'
          break
        case 10:// 异常设备
          type = 'NewDevice'
          break
        case 11:// 交通银行
          type = 'Auth_BankBCM'
          try {
            const parambcm:any = {
              phone: this.mobile,
              smsCode: this.code,
              grant_type: type,
              RegistSource: 2
            }
            const resbcm = await httpHelper.post({
              url: 'authorize/bankbcm',
              contentType: 'application/x-www-form-urlencoded',
              data: parambcm,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            this.loding = false
            if (resbcm.value.extend.benlaiZTHTML) {
              location.replace(resbcm.value.extend.benlaiZTHTML)
            } else {
              this.goTo(resbcm, 9, 11)
            }
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 11)
            this.errorMsg = err.msg
          }
          break
        case 15:
          type = 'Auth_YiQianBao'
          try {
            const parameter1:any = {
              phone: this.mobile,
              smsCode: this.code,
              authCode: getYiqianbaoAuthCode()
            }
            const sourece = getYiqianbaoSource()
            var pat = 'authorize/yiqianbao'
            if (sourece === 'app') {
              pat = 'authorize/yiqianbaoapp'
            }
            console.log('verification parameter1=' + parameter1)
            console.log('verification pat=' + pat)
            const res = await httpHelper.getAuthLogin(parameter1, pat)
            console.log('verification res=' + res)
            this.goTo(res, 9, 15)
            this.loding = false
          } catch (err) {
            this.code = ''
            this.codeArr = []
            console.log('verification err=' + err)
            this.goTo(err, 9, 15)
            this.errorMsg = err.msg
          }
          break
        case 16:
          type = 'Auth_Youchu'
          try {
            const paramyc:any = {
              phone: this.mobile,
              smsCode: this.code,
              grant_type: type
            }
            const youchu = await httpHelper.getAuthLogin(paramyc, 'authorize/youchu')
            console.log('youchu', youchu)
            if (youchu.code === 0) {
              this.goTo(youchu, this.smsType, this.grantType)
            }
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.errorMsg = err.msg
          }
          break
        case 17:// 索贝瑞
          type = 'Auth_Sobeirui'
          let url = getGoBackUrl()
          const queryStrings = parseSearch(`?${url.split('?')[1] || ''}`)
          try {
            const data:any = {
              thirdId: queryStrings.sodexo_openid,
              grant_type: 'Auth_Sobeirui',
              channel: queryStrings.channel,
              phone: this.mobile,
              smsCode: this.code
            }
            const res = await httpHelper.post({
              url: 'authorize/sobeirui',
              contentType: 'application/x-www-form-urlencoded',
              data: data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            location.replace(res.value.extend.gotoUrl)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 17)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 18:// 建行生活
          try {
            const param: any = {
              phone: this.mobile,
              smsCode: this.code,
              ccbParam: getCCBId(),
              RegistSource: 2
            }
            const microtask = await httpHelper.post({
              url: 'authorize/ccbSchoolCard',
              contentType: 'application/x-www-form-urlencoded',
              data: param,
              headers: {
                Authorization: configs.basicAuthorization
              }

            })
            this.loding = false
            if (microtask.code === 0) {
              setCCBSchoolId(microtask.value.extend.ccbSchoolCardUID)
              location.replace(microtask.value.extend.ccbSchoolCardPage)
            }
          } catch (err) {
            this.$toasted.show(err.msg || err.message)
          }
          break
        case 19:// 美餐
          type = 'Auth_Meican'
          try {
            const data:any = {
              phone: this.mobile,
              smsCode: this.code
            }
            const res = await httpHelper.post({
              url: 'authorize/meican',
              contentType: 'application/x-www-form-urlencoded',
              data: data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            console.log(res)
            // debugger
            location.replace(res.value.extend.gotoUrl)
            // this.goTo(res, 9, 19)
          } catch (err) {
            // debugger
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 19)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 20:// 良储
          type = 'Auth_Liangchu'
          let requestUrl = ''
          let data = {}
          if (this.isWeixin()) {
            requestUrl = 'authorize/liangchuDomain'
            data = {
              phone: this.mobile,
              loginSmsCode: this.code
            }
          } else {
            requestUrl = 'authorize/liangchu'
            data = {
              phone: this.mobile,
              loginSmsCode: this.code
            }
          }
          try {
            const res = await httpHelper.post({
              url: requestUrl,
              contentType: 'application/x-www-form-urlencoded',
              data: data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            console.log(res)
            setLiangChuAuth()
            location.replace(res.value.extend.gotoUrl)
          } catch (err) {
            // debugger
            this.code = ''
            this.codeArr = []
            this.goTo(err, 4, 20)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 21:// 中石化
          type = 'Auth_Sinopec'
          try {
            const data:any = {
              grant_type: 'Auth_Sinopec',
              phone: this.mobile,
              smsCode: this.code
            }
            const info = {
              ...JSON.parse(getSinoepcInfo()),
              ...data
            }
            const res = await httpHelper.post({
              url: 'authorize/sinopec',
              contentType: 'application/x-www-form-urlencoded',
              data: info,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            setSinoepcAuth()
            location.replace(res.value.extend.gotoUrl)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 21)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 22:// 关爱通
          try {
            const param: any = {
              phone: this.mobile,
              smsCode: this.code,
              authCode: getGATId(),
              RegistSource: 2
            }
            const microtask = await httpHelper.post({
              url: 'authorize/guanaitong',
              contentType: 'application/x-www-form-urlencoded',
              data: param,
              headers: {
                Authorization: configs.basicAuthorization
              }

            })
            this.loding = false
            if (microtask.code === 0) {
              setGATUserId(microtask.value.extend.benlaigatUserID)
              location.replace('https://m.benlai.com/')
            }
          } catch (err) {
            this.$toasted.show(err.msg || err.message)
          }
          break
        case 23:// 广东移动
          type = 'Auth_GDYD_B'
          try {
            const data:any = {
              grant_type: 'Auth_GDYD_B',
              userName: this.mobile,
              smsCode: this.code
            }
            const res = await httpHelper.post({
              url: 'authorize/guangdongyd',
              contentType: 'application/x-www-form-urlencoded',
              data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            location.replace(res.value.extend.gotoUrl)
          } catch (err) {
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 21)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 24:// 薪福通
          try {
            const res = await httpHelper.post({
              url: 'authorize/xinfutong',
              contentType: 'application/x-www-form-urlencoded',
              data: {
                phone: this.mobile,
                smsCode: this.code,
                data: getXFTData(),
                sign: getXFTSign()
              },
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            if (res.code === 0) {
              location.replace(res.value.extend.xft_lifeUrl)
            } else {
              this.$toasted.show(res.msg || res.message)
            }
          } catch (err) {
            this.goTo(err, 9, 24)
            this.$toasted.show(err.msg || err.message)
          }
          break
        case 104:// 常州移动
          // type = 'Auth_Meican'
          try {
            const data:any = {
              phone: this.mobile,
              smsCode: this.code
            }
            const res = await httpHelper.post({
              url: 'authorize/czMoveMealCard',
              contentType: 'application/x-www-form-urlencoded',
              data: data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            console.log(res)
            // debugger
            location.replace(res.value.extend.gotoUrl)
            // this.goTo(res, 9, 19)
          } catch (err) {
            // debugger
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 19)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
        case 47: // ccbnj 南京建行生活
          try {
            const data:any = {
              grant_type: 'Auth_CcbJxjk_NJ_MealCard',
              phone: this.mobile,
              loginSmsCode: this.code
            }
            const info = {
              ...JSON.parse(getCCBNJInfo()),
              ...data
            }
            const ccb = await httpHelper.getAuthLogin(info, 'authorize/ccbjxjkNJMealCard')
            if (ccb.code === 0) {
              setCCBNJInfo('')
              let value = ccb.value || {}
              setCCBNJcampusId(value.extend)
              location.replace(`${value.extend.gotoUrl}`)
            }
          } catch (err) {
            this.$toasted.show(err.msg || err.message)
          }
          break
        case 105:// 新中信防暑降温
          // type = 'Auth_Meican'
          try {
            const data:any = {
              phone: this.mobile,
              smsCode: this.code
            }
            const res = await httpHelper.post({
              url: 'authorize/citicWelfare',
              contentType: 'application/x-www-form-urlencoded',
              data: data,
              headers: {
                Authorization: configs.basicAuthorization
              }
            })
            console.log(res)
            // debugger
            location.replace(res.value.extend.gotoUrl)
            // this.goTo(res, 9, 19)
          } catch (err) {
            // debugger
            this.code = ''
            this.codeArr = []
            this.goTo(err, 9, 105)
            this.errorMsg = err.msg
          }
          this.loding = false
          return
      }
      if (this.grantType !== 5 && this.grantType !== 6 && this.grantType !== 7 && this.grantType !== 8 && this.grantType !== 15 && this.grantType !== 11 && this.grantType !== 18 && this.grantType !== 16) {
        const data:any = {
          grant_type: type,
          userName: this.mobile,
          smsCode: this.code
        }
        const pid = parseSearch(location.search).pid
        if (this.grantType === 4) {
          let iv = randomString(16)
          // let iv = 'P67P2jdDrNCpzQXt'
          const time = Math.round(Date.now() / 1000)
          const message = `${this.mobile}|${time}`
          // const message = '15858585858|1637056682'
          // const key = 'k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav'
          const key = CryptoJS.enc.Utf8.parse('k*^JjnSlZ&3Ro&w+fj%glN7%ITU9FLav')
          data.salt = iv
          iv = CryptoJS.enc.Utf8.parse(iv)
          const encrypted = CryptoJS.AES.encrypt(message,
            key,
            {
              iv: iv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            })
          data.userName = encrypted.toString()
        }
        try {
          if (pid) {
            data.thirdSource = pid
            const rediUrl = await httpHelper.requestThirdToken(data)
            const { gotoUrl = '' } = rediUrl.value.extend
            const query = gotoUrl.toString().indexOf('?') > 0 ? `&url=${encodeURIComponent(location.href)}` : `?url=${encodeURIComponent(location.href)}`
            console.log(`${gotoUrl}${query}`)
            location.replace(`${gotoUrl}${query}`)
            return
          }
          const res = await httpHelper.requestToken(data)
          this.errorMsg = ''
          // if (pid) {
          //   const rediUrl = await httpHelper.get({
          //     url: 'validate/gotoMall',
          //     data: {
          //       passportTypeId: parseInt(pid)
          //     }
          //   })
          //   const { gotoUrl = '' } = rediUrl.value.extend
          //   const query = gotoUrl.toString().indexOf('?') > 0 ? `&url=${encodeURIComponent(location.href)}` : `?url=${encodeURIComponent(location.href)}`
          //   console.log(`${gotoUrl}${query}`)
          //   location.replace(`${gotoUrl}${query}`)
          //   // debugger
          //   return
          // }
          if (parseSearch(location.search).cd === 'sign') {
            location.replace(`https://mc.benlai.com/e-sign.html?phone=${this.mobile}&redirect=${encodeURIComponent('https://life.benlai.com/m/V82HHR08')}`)
            return
          }
          this.goTo(res, this.smsType, this.grantType)
        } catch (err) {
          this.code = ''
          this.codeArr = []
          this.goTo(err, this.smsType, this.grantType)
          this.errorMsg = err.msg
        }
      }
      this.loding = false
    }
  }
  @Emit()
  public async sendCode (isToast:number = 0) {
    let mobile = this.mobile
    if (this.smsType !== 7 && this.smsType !== 8) {
      this.errorMsg = this.mobileError()
      if (this.errorMsg) {
        return
      }
    } else {
      mobile = ''
    }
    let isSecend = 0
    if (this.lastMobile === mobile) {
      isSecend = 1
    }
    this.lastMobile = mobile
    const [err, res] = await this.sendSMSCode(mobile, this.smsType, isSecend)
    if (err && err.code && err.msg) {
      this.errorMsg = err.msg
    } else if (isToast === 1) {
      this.$toasted.show('验证码获取成功！')
    }
  }
  @Emit()
  public mobileError () {
    if (!this.mobile) {
      return '手机号码不能为空'
    }
    return mobileRegExp.test(this.mobile) ? '' : '手机号格式不正确，请重新输入'
  }
}
